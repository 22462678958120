import React from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Selection,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Resize,
  ContextMenu,
  ExcelExport,
  PdfExport,
} from "@syncfusion/ej2-react-grids";
import {
  contextMenuItems,
  userGrid,
  userData,
} from "./config/GroupCategoryConfig";
import { Header } from "../../components";
import { useNavigate } from "react-router-dom";
import { BsFillPencilFill } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useContext, useState } from "react";
import StateContext from "../../contexts/ContextProvider";
import DeleteGroupCategory from "./DeleteGroupCategory";
import {
  AppBar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  } from "@material-ui/core";
  import { BiSearch } from "react-icons/bi";
 
  const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 650,
      borderCollapse: "collapse",
    },
    cell: {
      padding: "8px 0 8px 16px",
      textAlign: "start",
      // border: "1px solid " + theme.palette.divider,
    },
    cellRow: {
      // border: "1px solid " + theme.palette.divider,
      padding: "15px",
      textAlign: "start",
      width: "50%"
    },
    }));

const GroupCategory = () => {
  const { value } = useContext(StateContext);
  const { setValue } = useContext(StateContext);
  const [searchQuery, setSearchQuery] = useState('');
  const classes = useStyles();

  const editing = { allowDeleting: true };
  const { setData } = useContext(StateContext);

  const navigate = useNavigate();

  const addItem = () => {
    navigate("/groupCategory/add");
  };

  const [showData, setShowData] = useState([]);

  const random = 256;
  const loadData = async () => {
    // debugger;
    await axios
      .get(`https://api.orbitmart.co.in/api/v1/groupcategory`)
      .then((res) => {
        // debugger;
        setShowData(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  if (value == 200) {
    loadData();
    setValue(random);
  }

  function gridImage(itemId) {
    return (
      <img
        className=" h-20 md:ml-3"
        style={{ padding: "5px 0", marginLeft: "0", borderRadius: "5px" }}
        src={itemId.image}
        alt="Group Category"
      />
    );
  }

  function gridActions(showData) {
    // debugger;
    return (
      <div className="flex items-center gap-2">
        <h1
          style={{ color: "#8be78b", display: "flex" }}
          className="font-small leading-tight text-xl mt-0 mb-2 "
        >
          <Link style={{ marginRight: "auto" }} to="/groupcategory/edit">
            <BsFillPencilFill onClick={() => itemEdit(showData)} />
          </Link>
        </h1>
        <button
          style={{ color: "#ff5c8e", display: "flex", marginTop: 2.5 }}
          className="font-small leading-tight text-xl mt-0 mb-2 "
        >
          <DeleteGroupCategory showData={showData._id} />
        </button>
      </div>
    );
  }
  const itemEdit = (showData) => {
    setData(showData);
  };


  return (
    <div className="mx-6 md:m-10 mt-24 px-6 md:px-10 bg-white rounded-3xl">
      <div className="flex space-x-2 m-4 justify-end">
        <button onClick={() => addItem()} className="addBtn">
        New Record
        </button>
      </div>
      {/* )} */}
      <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "0 0 20px 0",
        padding: "8px",
        borderRadius: "20px",
        background: "rgb(0, 60, 126)",
        boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
      }}
      >
      <h1 className="m-4" style={{ color: "white", fontSize: "1.25rem" }}>
      Group Category
      </h1>
      <span
        className="flex space-x-2 m-4 justify-end"
        style={{ background: "white", borderRadius: "6px" }}
      >
        <input
        style={{
          padding: "4px 10px",
          outline: "none",
          borderRadius: "6px 0 0 6px",
          color: "black",
        }}
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        />
        <BiSearch
        style={{
          fontSize: "25px",
          color: "gray",
          cursor: "pointer",
          margin: "auto",
          paddingRight: "5px",
        }}
        />{" "}
      </span>
      </div>
      <TableContainer className={showData.length > 0 ? "table-container" : ""}>
      <Table>
        <TableHead>
        <TableRow>
				  <TableCell className={classes.cellRow}>Image</TableCell>
          <TableCell className={classes.cellRow}>Category</TableCell>
          <TableCell className={classes.cellRow}>Title</TableCell>
          <TableCell className={classes.cellRow} style={{textAlign: "start"}}>Actions</TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
        {showData.length > 0 ? (
          showData.map((item, i) => (
          <TableRow key={item._id}>
					  <TableCell className={classes.cell}>{gridImage(item)}</TableCell>
            <TableCell className={classes.cell}>{item.categoryId.map(cat=> cat.title)}</TableCell>
            <TableCell className={classes.cell}>{item?.title}</TableCell>
            <TableCell className={classes.cell} style={{textAlign: "start"}}>{gridActions(item)}</TableCell>
          </TableRow>
          ))
        ) : (
          <TableRow>
          <TableCell colSpan={4} align="center" style={{textAlign: "center"}}>
            No Data Available
          </TableCell>
          </TableRow>
        )}
        </TableBody>
      </Table>
      {/* {details?.length > 0 ? (
        <TablePagination
        count={details.length}
        component="div"
        page={currentPage}
        rowsPerPage={pageSize}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[2]}
        showFirstButton={true}
        showLastButton={true}
        ActionsComponent={CustomPaginationActions}
        />
      ) : null} */}
      </TableContainer>
    </div>
    );

  // return (
  //   <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
  //     <Header title="Group Category" />
  //     <div className="flex space-x-2 m-4 justify-end">
  //       <button type="button" className="addButton" onClick={() => addItem()}>
  //         New Record
  //       </button>
  //     </div>
  //     <GridComponent
  //       id="gridcomp"
  //       dataSource={showData}
  //       width="auto"
  //       allowPaging
  //       allowSorting
  //       allowExcelExport
  //       allowPdfExport
  //       pageSettings={{ pageCount: 5 }}
  //       contextMenuItems={contextMenuItems}
  //       selectionSettings={selectionsettings}
  //       editSettings={editing}
  //       toolbar={toolbarOptions}
  //       allowFiltering={true}
  //       // filterSettings={{ignoreAccent:true, type:'Menu'}}
  //       // filterSettings={{ ignoreAccent: true, type: "CheckBox" }}
  //       filterSettings={{ ignoreAccent: true, type: "Excel" }}
  //     >
  //       <ColumnsDirective>
  //         {userGrid.map((item, index) => (
  //           <ColumnDirective key={index} {...item} />
  //         ))}
  //         <ColumnDirective
  //           headerText="Actions"
  //           template={gridActions}
  //           textAlign="Center"
  //         />
  //       </ColumnsDirective>
  //       <Inject
  //         services={[
  //           Resize,
  //           Search,
  //           ContextMenu,
  //           Page,
  //           Selection,
  //           Toolbar,
  //           Edit,
  //           Sort,
  //           Filter,
  //           ExcelExport,
  //           PdfExport,
  //         ]}
  //       />
  //     </GridComponent>
  //   </div>
  // );
};

export default GroupCategory;

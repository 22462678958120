import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from './orbitmart-logo.png';
import NewLogo from '../../assets/orbit.png';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
//Login
import { useRef, useState, useEffect, useContext } from 'react';
import { API } from '../../config/api';
import StateContext from '../../contexts/ContextProvider';

// import axios from "../../api/axios";
// const LOGIN_URL = "/login";

// function Copyright(props) {
//   return (
//     <Typography
//       variant="body2"
//       color="text.secondary"
//       align="center"
//       {...props}
//     >
//       <Link color="inherit" href="https://mui.com/"></Link>
//     </Typography>
//   );
// }

const theme = createTheme();

const Register1 = () => {
	const { setAuth } = useContext(StateContext);
	const { setLoginData } = useContext(StateContext);
	const { loginData } = useContext(StateContext);

	const { setToken } = useContext(StateContext);

	console.log(setAuth);
	const userRef = useRef();
	const errRef = useRef();

	let isLoggedIn = false;

	const [ user, setUser ] = useState('');
	const [ pwd, setPwd ] = useState('');
	const [ errMsg, setErrMsg ] = useState(false);
	const [ validationError, setValidationError ] = useState({ email: false, password: false });
	const [ success, setSuccess ] = useState(false);

	// useEffect(() => {
	//   userRef.current.focus;
	// }, []);

	useEffect(
		() => {
			setErrMsg('');
		},
		[ user, pwd ]
	);

	// useEffect(() => {
	//   debugger;
	//   window.localStorage.setItem("MY_APP_STATE", JSON.stringify(loginData));
	// }, []);

	function storeLogin() {
		console.log(loginData);
		localStorage.setItem('MY_APP_STATE', JSON.stringify(loginData));
		console.log('Data Accessed');
	}

	useEffect(
		() => {
			storeLogin();
		},
		[ loginData ]
	);

	// const handleSubmit = (event) => {
	//   event.preventDef

	//   const data = new FormData(event.currentTarget);
	//   console.log({
	//     email: data.get("username"),
	//     password: data.get("password"),
	//   });
	// };

	async function handleSubmit(e) {
		// debugger;
		e.preventDefault();
		try {
			const data = new FormData(e.currentTarget);
			const finalData = {
				username: data.get('username'),
				password: data.get('password'),
				role: 'ADMIN'
			};
			if (finalData.username.length === 0) {
				setValidationError((prev) => {
					return { ...prev, email: true };
				});
			} else {
				setValidationError((prev) => {
					return { ...prev, email: false };
				});
			}
			if (finalData.password.length === 0) {
				setValidationError((prev) => {
					return { ...prev, password: true };
				});
			} else {
				setValidationError((prev) => {
					return { ...prev, password: false };
				});
			}
			console.log(finalData);
			let result = await fetch(`${API}/auth/admin/login`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json'
				},
				body: JSON.stringify(finalData)
			});
			result = await result.json();
			console.log(result);
			if (result.messageCode === 200) {
				debugger;
				setLoginData(result);
				sessionStorage.setItem('admin_login_data', JSON.stringify(result));
				sessionStorage.setItem('token', result.data.token);

				let config = {
					headers: {
						'Content-Type': 'application/json',
						'x-token': result.data.token
					}
				};
				await axios
					.put(
						`${API}/auth/edit-profile`,
						{ WEB_TOKEN: sessionStorage.getItem('FCM_TOKEN') },
						config
					)
					.then(async (res) => {
						await axios.get(`${API}/auth/get-profile`, config).then((res) => {
							setToken(result.data);
							storeLogin();
							setAuth(true);
							console.log(setAuth);
							<Link to="/Admin" />;
						});
					});
			} else {
				setAuth(false);
			}
		} catch (err) {
			setAuth(false);
			console.log(setAuth);
		}
	}

	// const handleSubmit = async (e) => {
	//   e.preventDefault();

	//   try {
	//     const response = await axios.post(
	//       LOGIN_URL,
	//       JSON.stringify({ username, password }),
	//       {
	//         mode: "cors",
	//         headers: { "Content-Type": "application/json" },
	//         withCredentials: true,
	//       }
	//     );
	//     console.log(JSON.stringify(response?.data));
	//     console.log(JSON.stringify(response));
	//     const accessToken = response?.data?.accessToken;
	//     const role = response?.data?.role;
	//     setAuth({ username, password, role, accessToken });
	//     setUser("");
	//     setPwd("");
	//     setSuccess(true);
	//   } catch (err) {
	//     if (!err?.response) {
	//       setErrMsg("No Server Response");
	//     } else if (err.response?.status === 400) {
	//       setErrMsg("Missing Username or Password");
	//     } else if (err.response?.status === 401) {
	//       setErrMsg("Unauthorized");
	//     } else {
	//       setErrMsg("Login Failed");
	//     }
	//     errRef.current.focus();
	//   }
	// };

	const emailHandler = (e) => {
		if (e.target.value.length === 0) {
			setValidationError((prev) => {
				return { ...prev, email: true };
			});
		} else {
			setValidationError((prev) => {
				return { ...prev, email: false };
			});
		}
	};
	const passwordHandler = (e) => {
		if (e.target.value.length === 0) {
			setValidationError((prev) => {
				return { ...prev, password: true };
			});
		} else {
			setValidationError((prev) => {
				return { ...prev, password: false };
			});
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<Box
					sx={{
						marginTop: 20,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						borderRadius: '25px',
						padding: 2,
						// padding: "25px 20px 25px 20px",
						border: '2px solid #003c7e'
						// boxShadow: "10px 10px 10px 10px #003c7e",
					}}>
					<img src={NewLogo} style={{ height: '50%' }} />
					<h1
						style={{
							fontFamily: 'Helvetica',
							fontWeight: 'bold',
							fontSize: '20px',
							color: '#003c7e',
							marginTop: 8
						}}>
						Admin Login
					</h1>
					<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
						<TextField
							margin="normal"
							helperText={validationError.email ? 'Email is required' : ''}
							error={validationError.email}
							required
							fullWidth
							onChange={emailHandler}
							id="username"
							label="Email Address"
							name="username"
							type="email"
							autoComplete="email"
							autoFocus
						/>
						<TextField
							margin="normal"
							helperText={validationError.password ? 'Password is required' : ''}
							error={validationError.password}
							onChange={passwordHandler}
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							id="password"
							autoComplete="current-password"
						/>
						{/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
						<Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
							Sign In
						</Button>
						{/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item style={{ alignContent: "center" }}>
                <Link href="#" variant="body2">
                  {"Don't have a Merchant account yet ?    Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
					</Box>
				</Box>
			</Container>
		</ThemeProvider>
	);
};

export default Register1;

import { Alert, Stack } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import StateContext from "../../contexts/ContextProvider";
import { ImCross } from "react-icons/im";
import { BsFillPencilFill } from "react-icons/bs";
// import { showErrorMsg } from "../../components/helper/message";
import "../view.css";

const EditEmployee = () => {
  const navigate = useNavigate();
  const { employeeInfo, setEmployees, distributor } = useContext(StateContext);
  const [addData, setAddData] = useState(employeeInfo);
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const token = sessionStorage.getItem("token");
  const [errMsg, setErrMsg] = useState(false);
  const [msg, setMsg] = useState("");
  var timeout;
  const displayErrMsg = (setErrMsg, timeout) => {
    setErrMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setErrMsg(false);
    }, 2000);
    return timeout;
  };
  const onSelectFile = (event) => {
    const selectedFiles = event.target.files[0];

    const fileExtension = selectedFiles.name.split(".").at(-1);
    const allowedFileTypes = ["jpg", "jpeg", "png"];
    if (!allowedFileTypes.includes(fileExtension)) {
      //window.alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
      //     alert("Only jpg/jpeg and png files are allowed!");
      setMsg(
        `File does not support. Files type must be ${allowedFileTypes.join(
          ", "
        )}`
      );
      timeout = displayErrMsg(setErrMsg, timeout);
      setLoading(false);
      return false;
    } else {
      setSelectedImages((previousImages) =>
        previousImages.concat(selectedFiles)
      );
      event.target.value = "";
    }
    // const selectedFiles = event.target.files[0];
    // setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
    // event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  const mainPage = () => {
    navigate("/employees");
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddData({ ...addData, [name]: value });
  };
  //    const handleSubmit = async(e) => {
  // 	console.log("submit")
  //     const editId = addData._id;
  //     e.preventDefault();
  //     const res = await fetch(
  //       `https://api.orbitmart.co.in/api/v1/auth/distributor/${editId}`,
  //       {
  //         method: "put",
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-access-token": token,
  //         },
  //         body: JSON.stringify(addData),
  //       }
  //     )
  //       .then((res) => {
  //         if (res.status === 200) {
  //           setAlert(true);
  //           setTimeout(() => {
  //             navigate("/employees");
  //           }, 1000);
  //         } else {
  //           setError(true);
  //           setTimeout(() => {
  //             navigate("/employees");
  //           }, 1000);
  //         }
  //       })
  //       .catch((err) => {
  //         setError(true);
  //         setTimeout(() => {
  //           navigate("/employees");
  //         }, 1000);
  //       });
  //     //   if (!res.ok) {
  //     //     const message = `An error has occured: ${res.status} - ${res.statusText}`;
  //     //     throw new Error(message);
  //     //   }
  //     //   const data = await res.json();
  //     //   const result = {
  //     //     status: res.status + "-" + res.statusText,
  //     //     headers: { "Content-Type": res.headers.get("Content-Type") },
  //     //     data: data,
  //     //   };
  //     //   console.log(result);
  //     // } catch (err) {
  //     //   console.log(err.message);
  //     // }
  //     // setAlert(true);
  //     //navigate("/alertedit")
  //   }

  console.log(addData.distributorId.firmName, "addData");
  const handleSubmit = async (e) => {
    console.log("submit");
    const editId = addData._id;
    e.preventDefault();

    try {
      // Upload image
      const uploadAPI = "https://api.orbitmart.co.in/api/v1/upload";
      const formData = new FormData();
      formData.append("image", selectedImages[0]);
      const imageUploadRes = await fetch(uploadAPI, {
        method: "POST",
        body: formData,
      });
      console.log(formData, "formData");
      if (!imageUploadRes.ok) {
        throw new Error(
          `Image upload failed: ${imageUploadRes.status} - ${imageUploadRes.statusText}`
        );
      }

      const imageUploadData = await imageUploadRes.json();
      const imageUrl = imageUploadData.files; // Assuming the response contains the URL of the uploaded image
      console.log(imageUrl, "imageUrl");
      // Update data with the new image URL
      const updatedData = { ...addData, avatar: imageUrl[0] };

      // Update data on the server
      const updateRes = await fetch(
        `https://api.orbitmart.co.in/api/v1/auth/distributor/${editId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
          body: JSON.stringify(updatedData),
        }
      );

      if (updateRes.status === 200) {
        setAlert(true);
        setTimeout(() => {
          navigate("/employees");
        }, 1000);
      } else {
        setError(true);
        setTimeout(() => {
          navigate("/employees");
        }, 1000);
      }
    } catch (err) {
      setError(true);
      console.error(err);
      setTimeout(() => {
        navigate("/employees");
      }, 1000);
    }
  };

  // const fileter = distributor.filter(
  //   (distributorList) =>
  //     addData.distributorId.firmName !== distributorList.firmName
  // ).map(distributorList => distributorList.firmName)}

  // console.log(fileter, "fileter for chck")

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {errMsg && showErrorMsg(msg, errMsg)}
      {alert ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="success">
            Employee Updated SuccessFully
          </Alert>
        </Stack>
      ) : null}
      {error ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="failure">
            Sorry, Employee Cannot be Updated at this moment. Please try Again!
          </Alert>
        </Stack>
      ) : null}
      <h4 className="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
        Edit Empolyee
      </h4>
      <div className="flex space-x-2 justify-end">
        <button type="button" className="backButton" onClick={() => mainPage()}>
          Back
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="grid xl:grid-cols-3  m:gap-2">
          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>First Name</label>
            <input
              required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="firstname"
              name="firstName"
              onChange={handleChange}
              value={addData.firstName}
              placeholder="Enter First Name"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Last Name</label>
            <input
              required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="lastname"
              name="lastName"
              value={addData.lastName}
              onChange={handleChange}
              placeholder="Enter Last Name"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Distributor</label>
            <select
              required
              className="form-select 
  w-full
  px-3
  py-1.5
  text-base
  font-normal
  text-gray-700
  bg-white bg-clip-padding bg-no-repeat
  border border-solid border-gray-300
  rounded
  transition
  ease-in-out
  m-0
  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="distributor"
              name="distributorId"
              value={addData.distributorId.firmName}
              onChange={handleChange}
              aria-label="Default select example"
            >
              <option defaultValue={addData.distributorId.firmName} selected>
                {addData.distributorId.firmName}
              </option>
              {distributor
                .filter(
                  (distributorList) =>
                    addData.distributorId.firmName !== distributorList.firmName
                )
                .map((distributorList) => (
                  <option key={distributorList._id} value={distributorList._id}>
                    {distributorList.firmName}
                  </option>
                ))}
            </select>
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Per Delivery Rate</label>
            <input
              required
              type="number"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="delivery"
              name="deliveryPerRate"
              value={addData.deliveryPerRate}
              onChange={handleChange}
              placeholder="Enter Per Delivery Rate"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Employee Status</label>
            <select
              required
              className="form-select 
  w-full
  px-3
  py-1.5
  text-base
  font-normal
  text-gray-700
  bg-white bg-clip-padding bg-no-repeat
  border border-solid border-gray-300
  rounded
  transition
  ease-in-out
  m-0
  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="status"
              name="status"
              value={addData.status}
              onChange={handleChange}
              aria-label="Default select example"
            >
              <option value="" disabled selected>
                Select Status
              </option>
              <option value="FREE">Free</option>
              <option value="BUSY">Busy</option>
            </select>
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Mobile Number</label>
            <input
              required
              type="number"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="mobile"
              name="mobile"
              value={addData.mobile}
              placeholder="Enter Mobile Number"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Email ID</label>
            <input
              required
              type="email"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="email"
              name="email"
              value={addData.email}
              placeholder="Enter Email Address"
            />
          </div>

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
        <label
          for="name"
          class="form-label inline-block mb-2 text-gray-700"
        >
          Password
        </label>
        <input
          type="text"
          class="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          id="password"
          name="password"
          value={addData.password}
          onChange={handleChange}
          placeholder=" Enter Password"
        />
      </div> */}

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Gender</label>
            <select
              required
              className="form-select 
  w-full
  px-3
  py-1.5
  text-base
  font-normal
  text-gray-700
  bg-white bg-clip-padding bg-no-repeat
  border border-solid border-gray-300
  rounded
  transition
  ease-in-out
  m-0
  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="gender"
              name="gender"
              value={addData.gender}
              onChange={handleChange}
              aria-label="Default select example"
            >
              <option defaultValue="Select Gender" selected>
                Select Gender
              </option>
              <option value="MALE">Male</option>
              <option value="FEMALE">Female</option>
            </select>
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Pin Code</label>
            <input
              required
              type="number"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="pincode"
              name="pincode"
              value={addData.pincode}
              onChange={handleChange}
              placeholder="Enter Pincode"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Address</label>
            <input
              required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="address"
              name="address"
              value={addData.address}
              onChange={handleChange}
              placeholder="Enter Full Address"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>City</label>
            <input
              required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="city"
              name="city"
              value={addData.city}
              onChange={handleChange}
              placeholder="Enter City Name"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>State</label>
            <input
              required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="state"
              name="state"
              value={addData.state}
              onChange={handleChange}
              placeholder="Enter State Name"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Country</label>
            <input
              required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="country"
              name="country"
              value={addData.country}
              onChange={handleChange}
              placeholder="Enter Country Name"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Aadhar Card</label>
            <input
              required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="adharcard"
              name="addharCard"
              value={addData.addharCard}
              onChange={handleChange}
              placeholder="Enter Aadhar Card Number"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Pan Card</label>
            <input
              required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="pancard"
              name="panCard"
              value={addData.panCard}
              onChange={handleChange}
              placeholder="Enter Pan Card Number"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Bank Name</label>
            <input
              required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="bankname"
              name="bankName"
              value={addData.bankName}
              onChange={handleChange}
              placeholder="Enter Bank Name"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Branch Name</label>
            <input
              required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="branchname"
              name="branchName"
              value={addData.branchName}
              onChange={handleChange}
              placeholder="Enter Branch Name"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Account Type</label>
            <select
              required
              className="form-select 
  w-full
  px-3
  py-1.5
  text-base
  font-normal
  text-gray-700
  bg-white bg-clip-padding bg-no-repeat
  border border-solid border-gray-300
  rounded
  transition
  ease-in-out
  m-0
  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="acctype"
              name="bankAccountType"
              value={addData.bankAccountType}
              onChange={handleChange}
              aria-label="Default select example"
            >
              <option value="" disabled selected>
                Select Account Type
              </option>
              <option value="SAVING">Saving Account</option>
              <option value="CURRENT">Current Account</option>
            </select>
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Account Number</label>
            <input
              required
              type="number"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="accnumber"
              name="accountNo"
              value={addData.accountNo}
              onChange={handleChange}
              placeholder="Enter Account Number"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>IFSC Code</label>
            <input
              required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="ifsccode"
              name="ifscCode"
              value={addData.ifscCode}
              onChange={handleChange}
              placeholder="Enter IFSC Code"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Employee Type</label>
            <select
              required
              className="form-select 
  w-full
  px-3
  py-1.5
  text-base
  font-normal
  text-gray-700
  bg-white bg-clip-padding bg-no-repeat
  border border-solid border-gray-300
  rounded
  transition
  ease-in-out
  m-0
  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="employee"
              name="empStatus"
              value={addData.empStatus}
              onChange={handleChange}
              aria-label="Default select example"
            >
              <option value="" disabled selected>
                Select Employee Type
              </option>
              <option value="SELF">Self</option>
              <option value="OTHER">Individual</option>
            </select>
          </div>
          <br />
          <div>
            <label style={{ paddingLeft: "12px" }}>Image</label>
            <div className="relative mt-1 flex justify-center w-40">
              <div className=" absolute right-0 top-0 z-10 flex">
                <label>
                  <BsFillPencilFill />
                  <input
                    id="file-upload"
                    name="file-upload"
                    disabled={selectedImages.length == 1}
                    accept="image/*"
                    type="file"
                    onChange={onSelectFile}
                    className="sr-only"
                  />
                </label>
              </div>

              <div className=" relative z-0 p-2 w-full mb-6 group">
                {selectedImages.length > 0 ? (
                  selectedImages.map((item, index) => {
                    return (
                      <div style={{ display: "inline-block" }} key={item}>
                        <p className="block-icon">
                          <img src={URL.createObjectURL(item)} alt="img" />
                          <ImCross
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandler(item)}
                            className="icon-belowtag"
                          />
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <img src={addData.avatar} alt="user-profile" />
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <div class="relative z-0 p-2 w-full mb-6 group">
        <label>Active/Inactive Status</label>
        <select
          class="form-select 
  w-full
  px-3
  py-1.5
  text-base
  font-normal
  text-gray-700
  bg-white bg-clip-padding bg-no-repeat
  border border-solid border-gray-300
  rounded
  transition
  ease-in-out
  m-0
  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          id="activeinactive"
           name="activeinactive" 
          onChange={handleChange} 
          value = {addData.ActiveInactive}      
  aria-label="Default select example"
        >
          <option value="">Select Active/Inactive</option>
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </select>
      </div> */}
        </div>
        <button className="addButton" type="submit">
          Update
        </button>
      </form>
      {/* <div class="flex space-x-2 justify-end">
    <button 
      type="button"  
      class="addButton"  
      onClick={handleSubmit} >
      Update
    </button>
  </div> */}
    </div>
  );
};

export default EditEmployee;

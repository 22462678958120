import * as React from "react";
import { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import { AiFillDelete } from "react-icons/ai";
import { RiErrorWarningLine } from "react-icons/ri";
import Modal from "@mui/material/Modal";
import "../../pages/view.css";
import { Link } from "react-router-dom";
import StateContext from "../../contexts/ContextProvider";
import { render } from "react-dom";
import { useNavigate } from "react-router-dom";
import Distributors from "./Distributors";

import { Alert, Stack } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 250,
  bgcolor: "background.paper",
  border: "2px solid redious #000",
  boxShadow: 24,
  p: 4,
};

const DeleteDistributorModal = (itemId) => {
  const [open, setOpen] = React.useState(false);

  const { setValue } = useContext(StateContext);

  const navigate = useNavigate();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function mainPage() {
    navigate("/distributors");
  }

  const [alert, setAlert] = useState(false);

  //Context
  //   const { deleteData } = useContext(StateContext);
  //   console.log(deleteData);

  //Item Delete

  //Distributor

  // const fetchData = () => {
  //   fetch("https://api.orbitmart.co.in/api/v1/auth/allDistributor")
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setTDatas(data);
  //     });
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const deleteItem = (_id) => {
  //   fetch(`https://api.orbitmart.co.in/api/v1/auth/distributor/${_id}`, {
  //     method: "DELETE",
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       if (data._id === _id) {
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  //   const itemDelete = (itemId) => {
  //     console.log(itemId);
  //   };

  // function refreshData() {
  //   render();
  //   navigate("/admin");
  // }

  // const itemDelete = (itemId) => {
  //   debugger;
  //   const id = Object.values(itemId);
  //   fetch(`https://api.orbitmart.co.in/api/v1/auth/distributor/${id[0]}`, {
  //     method: "DELETE",
  //   })
  //     .then((res) => res.json()) // or res.json()
  //     .then((res) => {
  //       console.log(res);
  //       setData(res);
  //     });
  //   handleClose();
  //   updateData();
  // };

  const itemDelete = (itemId) => {
    const id = Object.values(itemId);
    fetch(`https://api.orbitmart.co.in/api/v1/auth/distributor/${id[0]}`, {
      method: "DELETE",
    })
      .then(async (response) => {
        const data = await response.json();
        console.log(data);
        setValue(data.code);
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        console.log("Delete successful");
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    handleClose();
    mainPage();
  };

  return (
    <div>
      <button
        type="button"
        style={{ marginTop: 1, alignItems: "center" }}
        onClick={handleOpen}
      >
        <AiFillDelete />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {alert ? (
            <Stack
              sx={{ width: "30%" }}
              style={{
                position: "top",
                align: "center",
                left: "22%",
                bottom: "1%",
                width: "30%",
              }}
              spacing={2}
            >
              <Alert variant="filled" severity="success">
                Data Added Succesfully
              </Alert>
            </Stack>
          ) : null}
          <div
            style={{ color: "#ff5c8e", display: "flex" }}
            className="font-2xl leading-tight text-6xl mt-0 mb-2  flex space-x-2 justify-center"
          >
            <RiErrorWarningLine />
          </div>

          <h1 class="font-bold leading-tight  flex space-x-2 justify-center align-center text-xl mt-0 mb-0 text-black-600">
            Are you sure?
          </h1>

          <h4 class="flex space-x-2 justify-center aline-center">
            you wan't be able to revert this!
          </h4>

          <br />

          <div class="flex space-x-2 justify-center aline-center">
            <button type="button" class="backButton" onClick={handleClose}>
              Cancel
            </button>
            <button
              type="button"
              class="addButton"
              onClick={() => itemDelete(itemId)}
            >
              Yes, Delete!
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteDistributorModal;

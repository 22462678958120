import React, { useEffect, useState, useContext } from 'react';
import {
	AppBar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	makeStyles,
  } from "@material-ui/core";
  import { BiSearch } from "react-icons/bi";
import { contextMenuItems, userGrid, userData } from './config/CategorysConfig';
import { useNavigate } from 'react-router-dom';
import { BsFillPencilFill } from 'react-icons/bs';
import axios from 'axios';
import { Link } from 'react-router-dom';
import StateContext from '../../contexts/ContextProvider';
// import DeleteModal from "./DeleteModal";
import DeleteModal from './DeleteCategories';

const useStyles = makeStyles((theme) => ({
	table: {
	  minWidth: 650,
	  borderCollapse: "collapse", 
	},
	cell: {
	  padding: "8px 0 8px 16px", 
	  textAlign: "start",
	  // border: "1px solid " + theme.palette.divider,
	},
	cellRow: {
	  // border: "1px solid " + theme.palette.divider,
	  padding: "15px", 
	  textAlign: "start",
	},
  }));

const Categorys = () => {
	const [ details, setDetails ] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [dataLength, setDataLength] = useState(0)

	const classes = useStyles();

	const toolbarOptions = [ 'Search' ];

	const { value } = useContext(StateContext);
	const { setValue } = useContext(StateContext);

	const editing = { allowDeleting: true };

	const { setData } = useContext(StateContext);

	const random = 223;

	const fetchData = async() => {
		try{
			const response = await axios.get('https://api.orbitmart.co.in/api/v1/category')
			setDataLength(response.data.data.length);
			setDetails(response.data.data)
		}catch(err) {
			console.log(err, "error")
		}
	}

	console.log(details);


	const finalDatas = Object.values(details.data ?? {}).filter((val) => val.image[0].includes('.png' | '.jpg' | '.jpeg'));

	useEffect(() => fetchData(),[]);

	// async function showData() {
	//   debugger;
	//   await axios
	//     .get("https://api.orbitmart.co.in/api/v1/category")
	//     .then((res) => {
	//       debugger;
	//       setDetails(res.data.data);
	//       console.log(res);
	//     })
	//     .catch((err) => {
	//       console.log(err);
	//     });
	// }
	const navigate = useNavigate();

	// console.log('FilteredData: ', typeof details.data, details.data);
	// console.log(
	// 	'FilteredImages: ',
	// 	Object.values(details.data).filter((val) => val.image[0].includes('.png' | '.jpg' | '.jpeg'))
	// );

	if (value == 200) {
		fetchData();
		setValue(random);
	}

	function gridAction(details) {
		return (
			<div className="flex items-center gap-2">
				<h1
					style={{ color: '#8be78b', display: 'flex' }}
					className="font-small leading-tight text-xl mt-0 mb-2 ">
					<Link style={{ marginRight: 'auto' }} to={"/categorys/edit"}>
						<BsFillPencilFill onClick={() => itemEdit(details)} />
					</Link>
				</h1>
				<button
					style={{ color: '#ff5c8e', display: 'flex', marginTop: 2.5 }}
					className="font-small leading-tight text-xl mt-0 mb-2 ">
					<DeleteModal details={details._id} />
				</button>
			</div>
		);
	}

	const itemEdit = (details) => {
		setData(details);
	};
	const addItem = () => {
		navigate('/categorys/add');
	};

	function gridImage(itemId) {
		return <img className="rounded-xl h-20 md:ml-3" src={itemId.image[0]} alt="order-item" />;
	}


	return (
		<div className="mx-6 md:m-10 mt-24 px-6 md:px-10 bg-white rounded-3xl">
			<div className="flex space-x-2 m-4 justify-end">
			  <button onClick={() => addItem()} className="addBtn">
				New Record
			  </button>
			</div>
		  {/* )} */}
		  <div
			style={{
			  display: "flex",
			  justifyContent: "space-between",
			  alignItems: "center",
			  margin: "0 0 20px 0",
			  padding: "8px",
			  borderRadius: "20px",
			  background: "rgb(0, 60, 126)",
			  boxShadow:
				"0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
			}}
		  >
			<h1 className="m-4" style={{ color: "white", fontSize: "1.25rem" }}>
			Categories
			</h1>
			<span
			  className="flex space-x-2 m-4 justify-end"
			  style={{ background: "white", borderRadius: "6px" }}
			>
			  <input
				style={{
				  padding: "4px 10px",
				  outline: "none",
				  borderRadius: "6px 0 0 6px",
				  color: "black",
				}}
				type="text"
				placeholder="Search..."
				value={searchQuery}
				onChange={(e) => setSearchQuery(e.target.value)}
			  />
			  <BiSearch
				style={{
				  fontSize: "25px",
				  color: "gray",
				  cursor: "pointer",
				  margin: "auto",
				  paddingRight: "5px",
				}}
			  />{" "}
			</span>
		  </div>
		  <TableContainer className={details.length > 0 ? "table-container" : ""}>
			<Table>
			  <TableHead>
				<TableRow>
				  <TableCell className={classes.cellRow}>Image</TableCell>
				  <TableCell className={classes.cellRow}>Domain Type</TableCell>
				  <TableCell className={classes.cellRow}>Category</TableCell>
				  <TableCell className={classes.cellRow} style={{textAlign: "start"}}>Actions</TableCell>
				</TableRow>
			  </TableHead>
			  <TableBody>
				{dataLength > 0 ? (
				  details.map((item, i) => (
					<TableRow key={item._id}>
					  <TableCell className={classes.cell} style={{paddingLeft: '0'}}>{gridImage(item)}</TableCell>
					  <TableCell className={classes.cell}>{item?.merchantTypeId?.domainType}</TableCell>
					  <TableCell className={classes.cell}>{item?.title}</TableCell>
					  <TableCell className={classes.cell}>{gridAction(item)}</TableCell>
					</TableRow>
				  ))
				) : (
				  <TableRow>
					<TableCell colSpan={4} align="center" style={{textAlign: "center"}}>
					  No Data Available
					</TableCell>
				  </TableRow>
				)}
			  </TableBody>
			</Table>
			{/* {details?.length > 0 ? (
			  <TablePagination
				count={details.length}
				component="div"
				page={currentPage}
				rowsPerPage={pageSize}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				rowsPerPageOptions={[2]}
				showFirstButton={true}
				showLastButton={true}
				ActionsComponent={CustomPaginationActions}
			  />
			) : null} */}
		  </TableContainer>
		</div>
	  );

	// return (
	// 	<div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
	// 		<Header title="Categorys" />
	// 		<div className="flex space-x-2 m-4 justify-end">
	// 			<button type="button" className="addButton" onClick={() => addItem()}>
	// 				New Record
	// 			</button>
	// 		</div>
	// 		<GridComponent
	// 			id="gridcomp"
	// 			dataSource={finalDatas}
	// 			width="auto"
	// 			allowPaging
	// 			allowSorting
	// 			allowExcelExport
	// 			allowPdfExport
	// 			pageSettings={{ pageCount: 5 }}
	// 			contextMenuItems={contextMenuItems}
	// 			selectionSettings={selectionsettings}
	// 			editSettings={editing}
	// 			toolbar={toolbarOptions}
	// 			allowFiltering={true}
	// 			// filterSettings={{ignoreAccent:true, type:'Menu'}}
	// 			// filterSettings={{ ignoreAccent: true, type: "CheckBox" }}
	// 			filterSettings={{ ignoreAccent: true, type: 'Excel' }}>
	// 			<ColumnsDirective>
	// 				<ColumnDirective headerText="Image" textAlign="center" template={gridImage} />
	// 				{userGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
	// 				<ColumnDirective headerText="Actions" template={gridActions} textAlign="Center" />
	// 			</ColumnsDirective>
	// 			<Inject
	// 				services={[
	// 					Resize,
	// 					Search,
	// 					ContextMenu,
	// 					Page,
	// 					Selection,
	// 					Toolbar,
	// 					Edit,
	// 					Sort,
	// 					Filter,
	// 					ExcelExport,
	// 					PdfExport
	// 				]}
	// 			/>
	// 		</GridComponent>
	// 	</div>
	// );
};

export default Categorys;

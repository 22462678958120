import * as React from "react";
import { useEffect, useState,useContext } from "react";
import Box from "@mui/material/Box";
import { AiFillDelete } from "react-icons/ai";
import { RiErrorWarningLine } from "react-icons/ri";
import Modal from "@mui/material/Modal";
import "../../pages/view.css";
import { FiAlertCircle } from "react-icons/fi";
import StateContext from "../../contexts/ContextProvider";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 250,
  bgcolor: "background.paper",
  border: "2px solid redious #000",
  boxShadow: 24,
  p: 4,
};

const DeleteModal = () => {

  const {userId,setUsers,employeeId,orderData, setOrderData, setEmployees, orderId}=useContext(StateContext)
  const location=useLocation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete=async()=>{
if(location.pathname=="/employees")
{
  let response=await axios.delete(`https://api.orbitmart.co.in/api/v1/auth/distributor/${employeeId}`).then((res)=>res.data)
  if(response){
   await axios.get("https://api.orbitmart.co.in/api/v1/auth/allEmployee").then((res)=>{setEmployees(res.data.data)})
   handleClose();
   useNavigate('/employees')
}}
else if(location.pathname=="/users")
{
  let response=await axios.delete(`https://api.orbitmart.co.in/api/v1/auth/distributor/${userId}`).then((res)=>res.data)
  if(response){
   await axios.get("https://api.orbitmart.co.in/api/v1/auth/allUser").then((res)=>{setUsers(res.data.data)})
   handleClose();
   useNavigate('/users')
}
}
else if(location.pathname=="/orders")
{
  let response=await axios.delete(`https://api.orbitmart.co.in/api/v1/order/${orderId}`).then((res)=>res.data)
  if(response){
   await axios.get("https://api.orbitmart.co.in/api/v1/order").then((res)=>{setOrderData(res.data.data)})
   handleClose();
   useNavigate('/orders')
}
}
else
{
  // alert('delte')
}



  }
  //Item Delete

  //Distributor

  // const fetchData = () => {
  //   fetch("https://api.orbitmart.co.in/api/v1/auth/allDistributor")
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setTDatas(data);
  //     });
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const deleteItem = (_id) => {
  //   fetch(`https://api.orbitmart.co.in/api/v1/auth/distributor/${_id}`, {
  //     method: "DELETE",
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       if (data._id === _id) {
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  return (
    // <div>
    //   {/* <Button onClick={handleOpen}>Open modal</Button> */}
    //   <button
    //     type="button"
    //     style={{ marginTop: 1, alignItems: "center" }}
    //     onClick={()=>setOpen(true)}
    //   >
    //     {/* //     style={{ color: "white", backgroundColor: "#ff5c8e" }}
    // //     type="button"
    // //     onClick={handleOpen}
    // //     class="px-6
    // //   py-2.5
    // //   bg-blue-600
    // //   text-white
    // //   font-medium
    // //   text-xs
    // //   leading-tight
    // //   uppercase
    // //   rounded
    // //   shadow-md
    // //   hover:bg-blue-700 hover:shadow-lg
    // //   focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
    // //   active:bg-blue-800 active:shadow-lg
    // //   transition
    // //   duration-150
    // //   ease-in-out"
    // //    */}
    //     <AiFillDelete />
    //   </button>
    //   <Modal
    //     open={open}
    //     onClose={()=>setOpen(false)}
    //     aria-labelledby="modal-modal-title"
    //     aria-describedby="modal-modal-description"
    //   >
    //     <Box sx={style}>
        
    //       <div
    //         style={{ color: "#ff5c8e", display: "flex" }}
    //         className="font-2xl leading-tight text-6xl mt-0 mb-2  flex space-x-2 justify-center"
    //       >
    //         <RiErrorWarningLine />
    //       </div>

    //       <h1 class="font-bold leading-tight  flex space-x-2 justify-center align-center text-xl mt-0 mb-0 text-black-600">
    //         Are you sure?
    //       </h1>

    //       <h4 class="flex space-x-2 justify-center aline-center">
    //         you wan't be able to revert this!
    //       </h4>

    //       <br />

    //       <div class="flex space-x-2 justify-center aline-center">
    //         <button type="button" class="backButton" onClick={()=>setOpen(false)} >
    //           Cancel
    //         </button>
    //         <button type="button" class="addButton" onClick={handleDelete}>
    //           Yes, Delete!
    //         </button>
    //       </div>
    //     </Box>
    //   </Modal>
    // </div>
    <div>
    <button
      type="button"
      style={{ marginTop: 1, alignItems: "center" }}
      onClick={handleOpen}
    >
      <AiFillDelete />
    </button>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{ color: "#ff5c8e", display: "flex" }}
          className="font-2xl leading-tight text-6xl mt-0 mb-2  flex space-x-2 justify-center"
        >
          <RiErrorWarningLine />
        </div>

        <h1 class="font-bold leading-tight  flex space-x-2 justify-center align-center text-xl mt-0 mb-0 text-black-600">
          Are you sure?
        </h1>

        <h4 class="flex space-x-2 justify-center aline-center">
          you wan't be able to revert this!
        </h4>

        <br />

        <div class="flex space-x-2 justify-center aline-center">
          <button type="button" class="backButton" onClick={handleClose}>
            Cancel
          </button>
          <button
            type="button"
            class="addButton"
            onClick={handleDelete}
          >
            Yes, Delete!
          </button>
        </div>
      </Box>
    </Modal>
  </div>
  );
};

export default DeleteModal;

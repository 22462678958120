import React, { useEffect, useState, useContext } from 'react';
import {
	GridComponent,
	Inject,
	ColumnsDirective,
	ColumnDirective,
	Search,
	Page,
	Selection,
	Edit,
	Toolbar,
	Sort,
	Filter,
	Resize,
	ContextMenu,
	ExcelExport,
	PdfExport
} from '@syncfusion/ej2-react-grids';
// import { itemsTypeGrid, contextMenuItems } from './config/UnitQuantityConfig';
import { AiFillDelete } from 'react-icons/ai';
import { BsFillPencilFill } from 'react-icons/bs';
import '../css/view.css';
import { Header } from '../../components';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import StateContext from '../../contexts/ContextProvider';
import DeleteModal from './DeleteModal';
import {
	AppBar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	makeStyles,
	} from "@material-ui/core";
	import { BiSearch } from "react-icons/bi";
   
	const useStyles = makeStyles((theme) => ({
		table: {
		  minWidth: 650,
		  borderCollapse: "collapse",
		},
		cell: {
		  padding: "8px 0 8px 16px",
		  textAlign: "start",
		  // border: "1px solid " + theme.palette.divider,
		},
		cellRow: {
		  // border: "1px solid " + theme.palette.divider,
		  padding: "15px",
		  textAlign: "start",
		},
		}));

const UnitQuantity = () => {
	const navigate = useNavigate();
	const [searchQuery, setSearchQuery] = useState('');
  const classes = useStyles();

	const [ datas, setDatas ] = useState([ '' ]);
	const { setData } = useContext(StateContext);
	const { setValue } = useContext(StateContext);
	const { value } = useContext(StateContext);

	function addItem() {
		navigate('/unitPerQuantity/add');
	}

	const random = 251;

	function fetchData() {
		fetch('https://api.orbitmart.co.in/api/v1/unitPerQuantity')
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				setDatas(data.data);
			});
	}

	useEffect(() => {
		fetchData();
	}, []);

	if (value == 201) {
		fetchData();
		setValue(random);
	}

	console.log(datas);

	function gridActions(datas) {
		return (
			<div className="flex items-center justify-end gap-2 px-8">
				<h1
					style={{ color: '#8be78b', display: 'flex' }}
					className="font-small leading-tight text-xl mt-0 mb-2 ">
					<Link style={{ marginRight: 'auto' }} to={`/unit/Quantity/edit/${datas._id}`}>
						<BsFillPencilFill onClick={() => itemEdit(datas)} />
					</Link>
				</h1>
				<button
					style={{ color: '#ff5c8e', display: 'flex', marginTop: 2.5 }}
					className="font-small leading-tight text-xl mt-0 mb-2 ">
					<DeleteModal data={datas._id} />
				</button>
			</div>
		);
	}

	const itemEdit = () => {
		setData(datas);
	};

	return (
		<div className="mx-6 md:m-10 mt-24 px-6 md:px-10 bg-white rounded-3xl">
		  <div className="flex space-x-2 m-4 justify-end">
			<button
			 onClick={() => addItem()}
			  className="addBtn">
			New Record
			</button>
		  </div>
		  {/* )} */}
		  <div
		  style={{
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			margin: "0 0 20px 0",
			padding: "8px",
			borderRadius: "20px",
			background: "rgb(0, 60, 126)",

			boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
		  }}
		  >
		  <h1 className="m-4" 
          style={{ color: "white", fontSize: "1.25rem" }}
          >
		   Unit / Quantity
		  </h1>
		  <span
			className="flex space-x-2 m-4 justify-end"
			style={{ background: "white", borderRadius: "6px" }}
		  >
			<input
			style={{
			  padding: "4px 10px",
			  outline: "none",
			  borderRadius: "6px 0 0 6px",
			  color: "black",
			}}
			type="text"
			placeholder="Search..."
			value={searchQuery}
			onChange={(e) => setSearchQuery(e.target.value)}
			/>
			<BiSearch
			style={{
			  fontSize: "25px",
			  color: "gray",
			  cursor: "pointer",
			  margin: "auto",
			  paddingRight: "5px",
			}}
			/>{" "}
		  </span>
		  </div>
		  <TableContainer className={datas.length > 0 ? "table-container" : ""}>
		  <Table>
			<TableHead>
			<TableRow>
			  <TableCell className={classes.cellRow} style={{paddingLeft: '30px'}}>Quantity</TableCell>
			  <TableCell className={classes.cellRow} style={{textAlign: "right",paddingRight: '30px'}}>Actions</TableCell>
			</TableRow>
			</TableHead>
			 <TableBody>
			{datas.length > 0 ? (
			  datas.map((item, i) => (
			  <TableRow key={item._id}>
				<TableCell className={classes.cell} style={{paddingLeft: '30px'}}>{item?.title}</TableCell>
				<TableCell className={classes.cell} style={{textAlign: "right"}}>{gridActions(item)}</TableCell>
			  </TableRow>
			  ))
			) : (
			  <TableRow>
			  <TableCell colSpan={4} align="center" style={{textAlign: "center"}}>
				No Data Available
			  </TableCell>
			  </TableRow>
			)}
			</TableBody>
		  </Table>
		  {/* {details?.length > 0 ? (
			<TablePagination
			count={details.length}
			component="div"
			page={currentPage}
			rowsPerPage={pageSize}
			onPageChange={handleChangePage}
			onRowsPerPageChange={handleChangeRowsPerPage}
			rowsPerPageOptions={[2]}
			showFirstButton={true}
			showLastButton={true}
			ActionsComponent={CustomPaginationActions}
			/>
		  ) : null} */}
		  </TableContainer>
		</div>
		);
	
};

export default UnitQuantity;


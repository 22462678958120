import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineRollback } from 'react-icons/ai';
import './viewUser.css';
import StateContext from '../../contexts/ContextProvider';
import axios from "axios";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import UserDetails from "./UserDetails"


const ViewUser = () => {
	const { userInfo, setMyOrder, myOrder,setOrderInfo } = useContext(StateContext);
	const staticValue = 0
	const orbitMartCommissionLogic = 10;
	const subtotal = 145;
	const customerDiscount = 10;
	const WrongGst = 10
	const GstLogic = staticValue
	const DeliveryAmount = 10
	const TotalAmount = 165
	const payMerchant = 155
  const [text, setText] = useState("");
  const API = "https://api.orbitmart.co.in/api/v1"
  const [findOS, setFindOS] = useState([]);
  const [orders, setOrders] = useState(myOrder);
  const [userOrderStatus, setUserOrderStatus] = useState("")
  let navigate = useNavigate();
  const [tabVar, setTabVar] = useState('User-Details');

  useEffect(() => {
    const getUsersOrders = async () => {
      try {
        
        const res = await axios.get(`${API}/order/user/${userInfo._id}?page=1&limit=10`);
        setMyOrder(res.data.data);
        console.log(res.data.data, "res.data.data")
      } catch (err) {
        console.log(err);
      }
    };
    getUsersOrders();
  }, [userOrderStatus]);

 const OrderStatus = [...new Set(myOrder.map((os) => os.orderStatus))];

  const handleCategory = (e) => {
    let updateOS = findOS;
    if (e.target.checked) {
      updateOS.push(e.target.value);
    } else {
      const index = updateOS.indexOf(e.target.value);
      if (index !== -1) {
        updateOS.splice(index, 1);
      }
    }
    setFindOS(updateOS);
    const matches = myOrder.filter((or) => findOS.includes(or.orderStatus));

    setOrders(matches);
  };

  const handleOrder = (order) => {
    setOrderInfo(order);
    console.log(order);
    navigate("/users/view/OrderDetails");
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault(); // Prevent form submission
    filteredOrders();
  };

  const filteredOrders = text.trim() === '' ?
  orders :
  orders.filter(order => order.orderNo.toLowerCase().includes(text.toLowerCase()));
  console.log(tabVar);
  return (
    <div className=" m-4 mb-5 crd ms-5 me-5  ">
      <div className="jumbotron mb-auto ">
        <div className=" d-flex px-3">
          <h4 style={{padding: '5px 15px'}} className={`tab ${tabVar === 'User-Details' ? 'tab-active' : ''}`} onClick={()=> setTabVar('User-Details')}>Details</h4>
          <h4 style={{padding: '5px 15px'}}  className={`tab ${tabVar === 'Orders' ? 'tab-active' : ''}`} onClick={()=> setTabVar('Orders')}>Orders</h4>
        </div>

        <hr className="new2"></hr>

        {tabVar === 'Orders' ? (
          <div className="row bg-secondary crd">
          <div
            className="col-md-3 border-right bg-white bs  pt-2 px-3"
            style={{ height: "350px" }}
          >
            <h5 className="mb-3 mt-3 filter">
              Filters
              {/* <span className='fa fa-sliders'></span> */}
            </h5>
            <h6 className="mb-3 mt-3 filter-order">Order Status</h6>

            <div className=" bg-light ">
              {OrderStatus &&
                OrderStatus.map((os, index) => (
                  <div key={index} className="form-check ">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="category"
                      value={os}
                      id="flexCheckChecked"
                      checked={findOS.includes(os) ? true : false}
                      onChange={handleCategory}
                    />
                    <label
                      className="form-check-label "
                      htmlFor="flexCheckChecked"
                    >
                      <small className="text-capitalize">
                        {os.toLowerCase()}
                      </small>
                    </label>
                  </div>
                ))}
              {/* {categories &&
							categories.map(c => (
								<div key={c._id} className='form-check'>
									<input
										className='form-check-input'
										type='checkbox'
										name='category'
										value={c._id}
										id='flexCheckChecked'
										checked={categoryIds.includes(c._id)}
										onChange={handleCategory}
									/>
									<label
										className='form-check-label'
										htmlFor='flexCheckChecked'
									>
										{c.category}
									</label>
								</div>
							))} */}
            </div>
          </div>
          <div className="col-md-9  scroll-bar bg-secondary ps-5">
            <div className="search-box ">
              {/* <form onSubmit={handleSearch}> */}
              <form onSubmit={handleSearchSubmit}>
                <input
                  type="text"
                  name="search"
                  placeholder="Search by orderNo"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
                <input type="submit" value="Search" />
              </form>
            </div>
            {filteredOrders.length > 0 ? (
              filteredOrders.map((or, key) => (
                <div
                  className="row bs bg-white p-2 my-2"
                  onClick={(e) => handleOrder(or)}
                  key={key}
                >
                  <div className=" d-flex justify-content-between">
                    <Typography
                      component="h1"
                      variant="body1"
                      sx={{
                        color: "#003C7E",
                        pb: 2,
                        textTransform: "uppercase",
                      }}
                    >
                      {or.orderNo}
                    </Typography>

                    <Typography
                      component="h1"
                      variant="body1"
                      sx={{ color: "#003C7E", pb: 2 }}
                    >
                      {or.productDetails
                        .map((q) => q.quantity)
                        .reduce((a, b) => a + b)}
                      no of items
                    </Typography>
                  </div>
                  <div className="row">
                    <div className="d-flex justify-content-around">
                      <div className="p-2">
                        <Typography
                          component="h1"
                          variant="body1"
                          sx={{ color: "grey", pb: 2 }}
                        >
                          Order on
                        </Typography>
                        <Typography component="p" variant="body2">
                          {new Date(or.createdAt).toLocaleString("en-US", {
                            weekday: "short",
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}{" "}
                        </Typography>
                      </div>
                      <div className="p-2">
                        <Typography
                          component="h1"
                          variant="body1"
                          sx={{ color: "grey", pb: 2 }}
                        >
                          Order Status
                        </Typography>
                        <Typography
                          component="p"
                          variant="body2"
                          sx={{ color: "#28a745" }}
                        >
                          {or.orderStatus}
                        </Typography>
                      </div>
                      <div className="p-2">
                        {" "}
                        <Typography
                          component="h1"
                          variant="body1"
                          sx={{ color: "grey", pb: 2 }}
                        >
                          Amount
                        </Typography>
                        <Typography component="p" variant="body2">
                          ₹ {or.totalPrice}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  </div>
              ))
            ) : (
              <div className="mx-auto ms-3 crd  rounded text-center ">
                <div className="px-5 pb-5 ">
                  <div className=" flex-grow-1 bd-highlight  pb-3">
                    <h4 style={{ marginTop: "10px" }}>
                      Sorry, no results found
                    </h4>
                  </div>

                  <div className=" text-center ">
                    <div className="mb-2">
                      <img
                        src="https://cdn3.iconfinder.com/data/icons/business-strategy-planning/64/j-34-512.png"
                        alt="orders not found"
                        style={{ height: "100px", width: "100px" }}
                      />
                      <p>Edit search or go back to My Orders Page</p>

                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={()=>  navigate("/users")}
                      >
                        Go to My Users
                      </button>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            )}
          </div>
        </div>)
        : 
        <UserDetails userInfo={userInfo} />
        }
      </div>
    </div>
  );
  
	// return (
	// 	<div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl ">
  //     <div class="fixbox ">
  //       <div class="box" style={{background: '#003c7e', border: '1px solid #003c7e'}}>
  //         <div class="flex justify-end active">
  //           <div class="backIcon">
  //             <Link to="/users">
  //               <AiOutlineRollback />
  //             </Link>
  //           </div>
  //         </div>
  //         <div class="flex-container  tablefont1">
  //           <div>
  //             <h6 class="font-medium leading-tight text-base mt-0 mb-2 ">
  //               {/* {userInfo.distributorId.firstName} */}
  //               {/* here what data you want? */}
  //              Client Name: {userInfo.firstName} {userInfo.lastName} 
  //               {/* {userInfo.firstName} */}
  //             </h6>
  //             <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
  //               {/* {userInfo.data.distributorId.email} */}
  //               {userInfo.email}
  //             </h6>
  //             <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
  //               {/* {userInfo.data.distributorId.mobile} */}
  //               {userInfo.mobile}
  //             </h6>
  //           </div>

  //           {/* <div>
  //             <h6 class="font-medium leading-tight text-base mt-0 mb-2 ">
  //               KUDAL
  //             </h6>
  //             <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
  //               sitaramteli83@gmail.com
  //             </h6>
  //             <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
  //               7218650239
  //             </h6>
  //           </div> */}
  //         </div>
  //         <hr class="new1"></hr>
  //         <div className="flex-container  tablefont1">
  //           <div>
  //             <h6 class="font-medium leading-tight text-base mt-0 mb-2 ">
  //               ORDER DATE
  //             </h6>
  //             <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
  //               {new Date(userInfo?.createdAt).toLocaleString("en-US", {
  //                 weekday: "short",
  //                 day: "2-digit",
  //                 month: "short",
  //                 year: "numeric",
  //                 hour: "2-digit",
  //                 minute: "2-digit",
  //               })}
  //             </h6>
  //           </div>
  //           <div>
  //             <h6 class="font-medium leading-tight text-base mt-0 mb-2 ">
  //               ORDER NO.
  //             </h6>
  //             <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
  //               {userInfo?.orderNo}
  //             </h6>
  //           </div>
  //           <div>
  //             <h6 class="font-medium leading-tight text-base mt-0 mb-2 ">
  //               ORDER STATUS
  //             </h6>
  //             <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
  //               {userInfo?.orderStatus}
  //             </h6>
  //           </div>
  //           <div>
  //             <h6 class="font-medium leading-tight text-base mt-0 mb-2 ">
  //               ORDER TO
  //             </h6>
  //             <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
  //               {/* madhura arcade, ground floor C Wing, Ground Floor, Mathura Arcade, Gudhipur, Kudal, Maharashtra 416520, India */}
  //               {userInfo?.address}
  //             </h6>
  //           </div>
  //         </div>
  //       </div>
  //       <div class="tablefont1">
  //         <table class="min-w-full auto">
  //           <thead class="bg-white border-b">
  //             <tr>
  //               <th
  //                 scope="col"
  //                 class="text-sm font-medium text-gray-900  text-left"
  //               >
  //                 MERCHANT
  //               </th>
  //               <th
  //                 scope="col"
  //                 class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
  //               >
  //                 CATEGORY
  //               </th>
  //               <th
  //                 scope="col"
  //                 class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
  //               >
  //                 CLASS
  //               </th>
  //               <th
  //                 scope="col"
  //                 class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
  //               >
  //                 SUB CLASS
  //               </th>
  //               <th
  //                 scope="col"
  //                 class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
  //               >
  //                 ITEMS
  //               </th>
  //               <th
  //                 scope="col"
  //                 class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
  //               >
  //                 UNIT
  //               </th>
  //               <th
  //                 scope="col"
  //                 class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
  //               >
  //                 QTY
  //               </th>
  //               <th
  //                 scope="col"
  //                 class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
  //               >
  //                 AMOUNT
  //               </th>
  //               <th
  //                 scope="col"
  //                 class="text-sm font-medium text-gray-900 px-4 py-2 text-right "
  //               >
  //                 TOTAL
  //               </th>
  //             </tr>
  //           </thead>
  //           <tbody>
  //             {userInfo?.productDetails?.map((pro) => (
  //               <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
  //                 <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
  //                   {userInfo?.merchantId?.firmName}
  //                 </td>
  //                 <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
  //                   {pro?.productId?.categoryId?.title}
  //                 </td>
  //                 <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
  //                   {pro?.productId?.classificationId?.title}
  //                 </td>
  //                 <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
  //                   {pro?.productId?.subClassificationId?.title}
  //                 </td>

  //                 <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
  //                   {pro?.productId?.itemName}
  //                 </td>
  //                 <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
  //                   {pro?.productId?.unitQuantityId?.title}
  //                 </td>

  //                 <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
  //                   {/* {userInfo.productDetails[0].productId.stockQuantity} */}

  //                   {pro?.quantity}
  //                 </td>
  //                 <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
  //                   {/* {userInfo.totalPrice} */}
  //                   {pro?.productId?.itemPrice}
  //                 </td>
  //                 <td class="px-6  py-4 whitespace-nowrap text-md font-medium text-green-900 text-right">
  //                 ₹{(pro?.productId?.itemPrice * pro?.quantity)||0}
  //                 </td>
  //               </tr>
  //             ))}

  //             <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

  //               <td class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
  //                 SUB TOTAL
  //               </td>
  //               <td class="  px-6  py-4 whitespace-nowrap text-md font-small text-green-900 text-right">
  //               ₹{subtotal}
  //               </td>
  //             </tr>
  //             <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100 ">
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

  //               <td class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
  //                 DISCOUNT AMOUNT
  //                 <span className=" py-4 whitespace-nowrap text-md font-small text-red-500 text-right">(-)</span>
  //               </td>
  //               <td class="  px-6  py-4 whitespace-nowrap text-md font-small text-red-500 text-right">
  //               ₹{customerDiscount}
  //               </td>
  //             </tr>
  //             {/* <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

  //               <td class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
  //                 ORBIT MART COMMISSION
  //               </td>
  //               <td class=" ViewPrice px-6  py-4 whitespace-nowrap text-md font-medium text-green-900">
       
  //                 {orbitMartCommissionLogic}
  //               </td>
  //             </tr> */}

  //             <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100 ">
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

  //               <td class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
  //                 GST
  //               </td>
  //               {/* ViewPrice */}
  //               <td class="  px-6  py-4 whitespace-nowrap text-md font-small text-green-900 text-right">
  //               ₹{GstLogic}
  //               </td>
  //             </tr>
  //             <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

  //               <td class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
  //                 DELIVERY AMOUNT
  //               </td>
  //               <td class="  px-6  py-4 whitespace-nowrap text-md font-small text-green-900 text-right">
  //               ₹{DeliveryAmount}
  //               </td>
  //             </tr>

  //             <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

  //               <td class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
  //               <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
  //                 FINAL AMOUNT
  //                 {/* <span className="finailAmoutnSign">(+)</span> */}
  //                 <span className=" py-4 whitespace-nowrap text-md font-small text-blue-500 text-right">(+)</span>
  //               </td>
            
  //               <td class="  px-6  py-4 whitespace-nowrap text-md font-small text-green-900 text-right">
  //               ₹{parseInt(TotalAmount).toFixed(0)}
  //               </td>
  //             </tr>
  //           </tbody>
  //         </table>
  //       </div>
  //       {/* <div class="footerfont">
  //         <div class="flex-container  tablefont1">
  //           {userInfo?.orderStatus !== "PENDING" &&
  //           userInfo?.orderStatus !== "PROCESSING" &&userInfo?.orderStatus !== "REJECTED" ? (
  //             <div className="container_Assign ">
            
  //               <div className="Assign_hedding w-75">
  //                 <h3 className="Assign_hedding1">
  //                   Assign Employee Details
  //                   <span className="Assign_hedding2">*</span>
  //                 </h3>
  //               </div>
  //               <div className="AssignDtls">
  //                 <div>
  //                   <h5 className="DtlsName">
  //                     Order Pick Date and Time:-
  //                     <span className="asign1">
  //                       {userInfo?.distributorId?.createdAt}
  //                     </span>
  //                   </h5>
  //                 </div>
  //                 <div>
  //                   <h5 className="DtlsName">
  //                     {"Order Delivery Date and Time:-    "}
  //                     <span className="asign2">
  //                     {userInfo?.distributorId?.updatedAt}
  //                     </span>
  //                   </h5>
  //                 </div>
  //                 <div>
  //                   <h5 className="DtlsName">
  //                     Employee Name:- 
  //                     <span className="asign4 ">{empInfo?.firstName}</span>
  //                   </h5>
  //                 </div>
  //                 <div>
  //                   <h5 className="DtlsName">
  //                     Employee Email:-
  //                     <span className="asign4">{empInfo?.email}</span>
  //                   </h5>
  //                 </div>
  //                 <div>
  //                   <h5 className="DtlsName">
  //                     Employee Mobile:-
  //                     <span className="asign5">{empInfo?.mobile}</span>
  //                   </h5>
  //                 </div>
  //               </div>
  //             </div>
              
  //           ) : (
  //             ""
  //           )}
  //           <div class="right">
  //             <h4 class="font-medium leading-tight text-2xl mt-0 mb-2 text-blue-600">
  //               Total Amount
  //             </h4>
  //             <h3 class="font-large leading-tight text-3xl mt-0 mb-2 text-green-800">
  //               <span class="text-xxl inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded-full">
  //               ₹{parseInt(TotalAmount).toFixed(0)}
  //               </span>
  //             </h3>
  //             <h6 class="font-medium leading-tight text-base mt-0 mb-2 text-blue-600">
  //               Taxes Included
  //             </h6>
  //             {userInfo?.orderStatus !== "PENDING" &&
  //           userInfo?.orderStatus !== "PROCESSING" &&userInfo?.orderStatus !== "REJECTED" ?(<div className="tbody1">Pay amount to Merchant:<h6>₹{payMerchant}</h6></div>):""}
  //           </div>
  //         </div>
  //       </div> */}
  //       {/*  */}
  //     </div>
  //   </div>
	// );
};

export default ViewUser;

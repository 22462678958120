import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ImCross } from "react-icons/im";
import "../view.css";
//import StateContext from "../../contexts/ContextProvider";
import axios from "axios";
import { Alert, Stack } from "@mui/material";
//DropdownNew
import { BsFillPencilFill } from "react-icons/bs";
import blank from "../../assets/blank.jpg";

import Select from "react-select";

const AddGroupCategory = () => {
  const navigate = useNavigate();

  const mainPage = () => {
    navigate("/groupCategory");
  };
  // const { data } = useContext(StateContext);
  const [types, setTypes] = useState([]);
  const [data, setData] = useState([""]);
  const [name, setName] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);

  const [gValue, setGValue] = useState([]);

  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    getTypes();
  }, []);

  // const handleId = (e) => {
  //   debugger;
  //   let index = e.target.selectedIndex;
  //   let arr = e.target.childNodes[index].id;
  //   if (document.getElementById(arr).style.backgroundColor === "skyblue") {
  //     document.getElementById(arr).style.backgroundColor = "";
  //     setData(data.filter((item) => item !== arr));
  //   } else {
  //     document.getElementById(arr).style.backgroundColor = "skyblue";
  //     setData((data) => [...data, arr]);
  //   }
  //   console.log(data);
  // };
  console.log(selectedImages);

  const handleName = (e) => {
    // debugger;
    setName(e.target.value);
  };
  const getTypes = async () => {
    // debugger;
    const res = await axios.get(`https://api.orbitmart.co.in/api/v1/category`);
    setTypes(res.data.data);
  };
  
  const GroupOp = types.filter((type) =>type?.merchantTypeId?.domainType == "Grocery")
  .map((opt) => ({
    label: opt.title,
    value: opt._id,
  }));

  const onSelectFile = (event) => {
    // const selectedFiles = event.target.files;
    const selectedFiles = event.target.files[0];
    // const selectedFilesArray = Array.from(selectedFiles);

    // const imagesArray = selectedFilesArray.map((file) => {
    //   return file;
    // });

    setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
    // setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

//   async function postData() {

//     var categoryList = [];
//     for (let i = 0; i < gValue.length; i++) {
//       categoryList.push(gValue[i].value);
//     }
//    if(name==""||categoryList==="")
// { setError(true);
//   setTimeout(() => {
//     setError(false);
//   }, 1000);

// }
// else{


//     console.log(categoryList);
//     const uploadAPI = "https://api.orbitmart.co.in/api/v1/upload";
//     const uploadData = "https://api.orbitmart.co.in/api/v1/groupcategory/add";
//     const formData = new FormData();
//     for (let i = 0; i < selectedImages.length; i++) {
//       formData.append("Image", selectedImages[i]);
//     }

//     axios.post(uploadAPI, formData).then((res) => {
//       console.log(res);
//       var newimg = res.data.files;
//       var coverPhoto = newimg[0];
//       debugger;

  
//       console.log(coverPhoto);
//       // console.log(bannerName);
//       // console.log(locationName);
//       // console.log(pincode);

//       const finalData = {
//         title: name,
//         categoryId: categoryList,
//         image: coverPhoto
//       };
  
//       console.log(finalData);
//       const requestOptions = {
//         method: "POST",
//         headers: { "Content-Type": "application/json", "x-token": token },
//         body: JSON.stringify(finalData),
//       };
//       fetch(uploadData, requestOptions)
//         .then(async (response) => {
//           const isJson = response.headers
//             .get("content-type")
//             ?.includes("application/json");
//           const data = isJson && (await response.json());
//           console.log(data);
//           setAlert(true);
//           setTimeout(() => {
//             mainPage();
//           }, 1000);
//           // check for error response
//           if (!response.ok) {
//             setError(true);
//             setTimeout(() => {
//               mainPage();
//             }, 1000);
//             // get error message from body or default to response status
//             const error = (data && data.message) || response.status;
//             return Promise.reject(error);
//           }
//         })
//         .catch((error) => {
//           setError(true);
//           setTimeout(() => {
//             mainPage();
//           }, 1000);
//           console.log(error.message);
//           console.error("There was an error!", error);
//         });
//     });

//     // try {
//     //   const res = await fetch(
//     //     "https://api.orbitmart.co.in/api/v1/groupcategory/add",
//     //     {
//     //       method: "post",
//     //       headers: {
//     //         "Content-Type": "application/json",
//     //       },
//     //       body: JSON.stringify(finalData),
//     //     }
//     //   );
//     //   if (!res.ok) {
//     //     setError(true);
//     //     setTimeout(() => {
//     //       setError(false);
//     //     }, 1000);
//     //     const message = `An error has occured: ${res.status} - ${res.statusText}`;
//     //     throw new Error(message);
//     //   }
//     //   const data = await res.json();
//     //   const result = {
//     //     status: res.status + "-" + res.statusText,
//     //     headers: {
//     //       "Content-Type": res.headers.get("Content-Type"),
//     //       "Content-Length": res.headers.get("Content-Length"),
//     //     },
//     //     data: data,
//     //   };
//     //   console.log(data);
//     //   console.log(result);
//     //   setAlert(true);
//     //   setTimeout(() => {
//     //     mainPage();
//     //   }, 1000);
//     // } catch (error) {
//     //   setError(true);
//     //   setTimeout(() => {
//     //     mainPage();
//     //   }, 1000);
//     //   console.log(error.message);
//     // }

//   }
//   }
async function postData() {
  var categoryList = [];
  for (let i = 0; i < gValue.length; i++) {
      categoryList.push(gValue[i].value);
  }
  if (name == "" || categoryList.length === 0) {
      setError(true);
      setTimeout(() => {
          setError(false);
      }, 1000);
  } else {
      console.log(categoryList);
      const uploadAPI = "https://api.orbitmart.co.in/api/v1/upload";
      const uploadData = "https://api.orbitmart.co.in/api/v1/groupcategory/add";
      const formData = new FormData();
      for (let i = 0; i < selectedImages.length; i++) {
          formData.append("Image", selectedImages[i]);
      }

      try {
          const res = await axios.post(uploadAPI, formData);
          console.log(res);
          var newimg = res.data.files;
          var coverPhoto = newimg[0];
          const token = sessionStorage.getItem("token");

          const finalData = {
              title: name,
              categoryId: categoryList,
              image: coverPhoto
          };

          console.log(finalData);

          const requestOptions = {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
                  "x-token": token
              },
              body: JSON.stringify(finalData)
          };

          const response = await fetch(uploadData, requestOptions);
          const isJson = response.headers.get("content-type")?.includes("application/json");
          const data = isJson && await response.json();

          if (response.ok) {
              console.log(data);
              setAlert(true);
              setTimeout(() => {
                  mainPage();
              }, 1000);
          } else {
              const error = (data && data.message) || response.status;
              setError(true);
              setTimeout(() => {
                  mainPage();
              }, 1000);
              throw new Error(error);
          }
      } catch (error) {
          setError(true);
          setTimeout(() => {
              mainPage();
          }, 1000);
          console.error("There was an error!", error);
      }
  }
}


  function DropCheck() {
    debugger;
    var checkList = document.getElementById("list1");
    checkList.getElementsByClassName("anchor")[0].onclick = function (evt) {
      debugger;
      if (checkList.classList.contains("visible")) {
        debugger;
        checkList.classList.remove("visible");
      } else {
        debugger;
        checkList.classList.add("visible");
      }
    };
  }
  function handleCheckBox(e) {
    debugger;
    let checkbox = e.target.parentNode.id;
    if (e.target.checked) {
      setData((data) => [...data, checkbox]);
    } else {
      setData(data.filter((item) => item !== checkbox));
    }
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {alert ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="success">
            Data Added Successfully
          </Alert>
        </Stack>
      ) : null}
      {error ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="error">
            Sorry, Data Cannot be Added at this moment. Please try Again!
          </Alert>
        </Stack>
      ) : null}
      <h4 class="font-medium leading-tight text-3xl mt-0 mb-2 text-blue-600">
        Add Group Category
      </h4>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          class="backButton"
          style={{ width: "8%" }}
          onClick={() => mainPage()}
        >
          Back
        </button>
        <button
          type="button"
          class="addButton"
          onClick={postData}
          style={{ width: "8%" }}
        >
          Add
        </button>
      </div>
      <form>
        <div class="grid xl:grid-cols-3  m:gap-2">
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Group Category</label>
            <Select
              options={GroupOp}
              onChange={(opt) => setGValue(opt)}
              isMulti
            />
          </div>
          {/* 
          <div id="list1" class="dropdown-check-list " tabindex="100">
            <label>Group Category</label>
            <span class="anchor" onClick={DropCheck}>
              Select Fruits
            </span>
            <ul class="items">
              {types.map((typeget) => (
                <li value={typeget.title} id={typeget._id}>
                  <input type="checkbox" onClick={(e) => handleCheckBox(e)} />
                  {typeget.title}
                </li>
              ))}
            </ul>
          </div> */}

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Group Category Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="name"
              name="name"
              onChange={handleName}
              placeholder="Enter Group Category Name"
            />
          </div>

          <div>
            <label class="block text-sm font-medium text-gray-700">
            Group Category Image
            </label>
            <div class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
              {/* <div class="space-y-1 text-center"> */}
              <div class="flex text-sm text-gray-600">
                <label
                  for="file-upload"
                  class="relative top-0 right-0 cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                >
                  <BsFillPencilFill />
                  <input
                    id="file-upload"
                    name="file-upload"
                    accept="image/*"
                    type="file"
                    onChange={onSelectFile}
                    class="sr-only"
                  />
                </label>
              </div>
              {/* </div> */}
              <div class="relative z-0 p-2 w-full mb-6 group">
                {selectedImages.length > 0 ? (
                  selectedImages.map((item, index) => {
                    return (
                      <div style={{ display: "inline-block" }} key={item}>
                        <p className="block-icon">
                          <img src={URL.createObjectURL(item)} alt="img" />
                          <ImCross
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandler(item)}
                            className="icon-belowtag"
                          />
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <img src={blank} alt="user-profile" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddGroupCategory;

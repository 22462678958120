import React, {useContext, useState} from 'react';
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import  StateContext  from "../../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";
import "./orderDetails.css";


function OrderDetails() {
    const { orderInfo, setMyOrder } = useContext(StateContext);
    const [activestep, setActiveStep] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [totItem, setTotItem] = useState(orderInfo.productDetails.length);
    const [gst, setGst] = useState(0);
    const userId = window.localStorage.getItem("userId");
    const [showStep, setShowStep] = useState(false);
    let navigate = useNavigate();

   console.log(orderInfo, "orderInfo")

  return (
    <>
     <Grid container component="main">
        <Grid
          item
          sm={10}
          md={8}
          lg={7}
          sx={{ pt: 3, mx: "auto", mb: 3 }}
          //  component={Paper}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              columnGap: "3rem",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", paddingTop: "10px", }}
              style={{marginLeft: '18px'}}
              color="#000a12"
            >
              Item List
            </Typography>
            <div
              style={{
                padding: "10px 10px",
                borderRadius: "2px",
                backgroundColor: "#ff9d3f",
                color: "white",
              }}
              variant="raised"
              size="small"
            >
              Total Item : {totItem}
            </div>
          </Box>
          <Box sx={{ mt: 2, mx: 2 }}>
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", paddingTop: "10px" }}
              color="#000a12"
            >
              Order Summary
            </Typography>
            <table style={{width: '100%'}}>
              {orderInfo.productDetails.map((pro, key) => (
                <tr key={key} className='orderIfor'>
                  <td>
                    <img
                      className="h-25"
                      src={pro.productId.image[0]}
                      alt={pro.productId.itemName}
                    />
                  </td>
                  <td>
                    <span className="text-cl-bl fs-6 fw-bold">
                      {pro.productId.itemName}
                    </span>
                    <br />
                    <small className="fw-bold">
                      {pro.productId.unitQuantityId.title}
                    </small>
                    <br />
                    <small className="fw-bold">Qty:{pro.quantity}</small>
                  </td>

                  <td>
                    {" "}
                    <span className="text-right text-cl-bl">
                      {" "}
                      ₹{pro.productId.itemPrice}
                    </span>
                  </td>
                </tr>
              ))}
            </table>
            <table className="order-total-price ">
              <tr>
                <td>Sub total </td>
                <td>
                  ₹{" "}
                  {orderInfo.productDetails
                    .map((pr) => pr?.productId?.itemPrice * pr?.quantity)
                    .reduce((a, b) => a + b, 0)
                    .toFixed(2)}
                </td>
                {/* {orderInfo.totalPrice}</td> */}
              </tr>
              <tr className="text-muted">
                <td>Discount</td>
                <td className="text-danger">₹{discount}</td>
              </tr>

              <tr className="text-muted">
                <td>Gst</td>
                <td>₹ {gst}</td>
              </tr>
              <tr className="text-muted">
                <td>Delivery charge</td>
                <td>₹0</td>
              </tr>

              <tr className="grand">
                <td>
                  <b>Grand Total</b>
                </td>

                <td className="text-cl-bl">₹{orderInfo.totalPrice}</td>
              </tr>
            </table>
            {/* {orderInfo.paymentMode === "COD" ? (
              <Box sx={{ mt: 2, mb: 2 }}>
                <h6>
                  <b>Payment Method</b>
                </h6>
                <div className="alert alert-success">Cash on delivery</div>
              </Box>
            ) : (
              ""
            )}
            {showAction()} */}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default OrderDetails
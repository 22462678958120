import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import { BsFillPencilFill } from "react-icons/bs";
import DeleteModal from "../deleteModal/DeleteModal";
import { contextMenuItems, userGrid, userData } from "./config/UserConfig";
import { Header } from "../../components";
import StateContext from "../../contexts/ContextProvider";
import axios from "axios";
import {
	FirstPageOutlined,
	LastPageOutlined,
	NavigateBefore,
	NavigateNext,
  } from "@material-ui/icons";
import {
  AppBar,
  Divider,
  Fade,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Backdrop,
  makeStyles,
} from "@material-ui/core";
import { BiSearch } from "react-icons/bi";
import { Button, CircularProgress, Toolbar, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: "collapse",
  },
  cell: {
    padding: "8px 0px 8px 16px",
    textAlign: "start",
    // border: "1px solid " + theme.palette.divider,
  },
  cellRow: {
    // border: "1px solid " + theme.palette.divider,
    padding: "15px",
    textAlign: "start",
  },
}));

const Users = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const classes = useStyles();
  const { users, setUsers, setUserId, userInfo, setUserInfo, setOpen } =
    useContext(StateContext);
	const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0); // Current page number
  const [pageSize, setPageSize] = useState(10);
  const editing = { allowDeleting: true };
  useEffect(async () => {
    await axios
      .get(`https://api.orbitmart.co.in/api/v1/auth/allUser`)
      .then((res) => setUsers(res.data.data));
  }, [currentPage]);
  const gridUsersStatus = (props) => (
    <button
      type="button"
      style={{ 
        background: fetchStatusColor(props.orderStatus)
       }}
      className="text-white py-1 px-2 capitalize rounded-2xl text-md"
    >
      {props.orderStatus === true ? "busy" : "Free"}
      {/* {console.log(props.orderStatus)} */}
    </button>
  );
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  const changeToFirstPage = () => {
    setLoading(true);
    setCurrentPage(0);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const changeToPrevPage = () => {
    setLoading(true);
    setCurrentPage(currentPage - 1);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const changeToNextPage = () => {
    setLoading(true);
    setCurrentPage(currentPage + 1);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const changeToLastPage = () => {
    setLoading(true);
    setCurrentPage(Math.ceil(users?.length / pageSize) - 1);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const fetchStatusColor = (status) => {
    switch (status) {
      case true:
        return "#EE4E4E";
      case false:
        return "#A1DD70";
    }
  };

  const gridItemAction = (props) => (
    <div className="flex items-center justify-center gap-2">
      <h1
        style={{ color: "#03c9d7", display: "flex" }}
        className="font-small leading-tight text-xl mt-0 mb-2 "
        onClick={(e) => setUserInfo(props)}
      >
        <Link
          style={{ marginLeft: "auto", marginRight: "auto" }}
          to="/users/view"
        >
          <FiEye />
        </Link>
      </h1>
      <h1
        style={{ color: "#ff5c8e", display: "flex", marginTop: 2.5 }}
        className="font-small leading-tight text-xl mt-0 mb-2 "
        onClick={() => {
          setUserId(props._id);
        }}
      >
        <DeleteModal />
      </h1>
    </div>
  );

//   const filteredData = users?.slice(
//     currentPage * pageSize,
//     currentPage * pageSize + pageSize
//   );
const filteredData = users
  ?.filter((user) =>
    user.firstName.toLowerCase().includes(searchQuery.toLowerCase())
  )
  .slice(currentPage * pageSize, currentPage * pageSize + pageSize);

  console.log("Users: ", users);

  // const deleteItem = (id) => {
  //   console.log(id);
  // };

  return (
    // <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
    // 	<Header title="Users" />
    // 	{/* <Header category="Page" title="Users" /> */}
    // 	<GridComponent
    // 		id="gridcomp"
    // 		dataSource={users}
    // 		width="auto"
    // 		allowPaging
    // 		allowSorting
    // 		allowExcelExport
    // 		allowPdfExport
    // 		pageSettings={{ pageCount: 5 }}
    // 		contextMenuItems={contextMenuItems}
    // 		selectionSettings={selectionsettings}
    // 		editSettings={editing}
    // 		toolbar={toolbarOptions}
    // 		allowFiltering={true}
    // 		filterSettings={{ ignoreAccent: true, type: 'Excel' }}>
    // 		{/* <ColumnsDirective>
    //   {userGrid.map((item, index) => (
    //     <ColumnDirective key={index} {...item} />
    //   ))}
    // </ColumnsDirective> */}

    // 		<ColumnsDirective>
    // 			<ColumnDirective
    // 				field="_id"
    // 				headerText="ID"
    // 				textAlign="center"
    // 				isPrimaryKey={true}
    // 				visible={false}
    // 			/>
    // 			<ColumnDirective field="firstName" headerText="First Name" textAlign="center" width="100" />
    // 			<ColumnDirective field="lastName" headerText="Last Name" textAlign="center" width="100" />

    // 			<ColumnDirective field="email" headerText="Email" textAlign="Center" width="100" />
    // 			<ColumnDirective field="mobile" headerText="Mobile" textAlign="Center" width="100" />
    // 			<ColumnDirective field="pincode" headerText="Pincode" textAlign="Center" width="100" />
    // 			<ColumnDirective
    // 				field="_id"
    // 				headerText="Status"
    // 				textAlign="Center"
    // 				width="100"
    // 				template={gridUsersStatus}
    // 			/>
    // 			<ColumnDirective
    // 				field="_id"
    // 				headerText="Action"
    // 				textAlign="Center"
    // 				width="60"
    // 				template={gridItemAction}
    // 				allowFiltering={false}
    // 			/>
    // 		</ColumnsDirective>
    // 		<Inject
    // 			services={[
    // 				Resize,
    // 				Search,
    // 				ContextMenu,
    // 				Page,
    // 				Selection,
    // 				Toolbar,
    // 				Edit,
    // 				Sort,
    // 				Filter,
    // 				ExcelExport,
    // 				PdfExport
    // 			]}
    // 		/>
    // 	</GridComponent>
    // </div>
    <div className="m-6 md:m-10 mt-24 p-6 md:px-10 bg-white rounded-3xl">
      <AppBar
        position="static"
        style={{ backgroundColor: "#003c7e", borderRadius: "20px" }}
      >
        <Toolbar style={{ justifyContent: "space-between" }}>
          <Typography variant="h6">Users ({users?.length})</Typography>
          <span
            className="flex space-x-2 justify-end"
            style={{ background: "white", borderRadius: "6px" }}
          >
            <input
              style={{
                padding: "4px 10px",
                outline: "none",
                borderRadius: "6px 0 0 6px",
                color: "black",
              }}
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <BiSearch
              style={{
                fontSize: "25px",
                color: "gray",
                cursor: "pointer",
                margin: "auto",
                paddingRight: "5px",
              }}
            />{" "}
            {/*  this is the search icon */}
          </span>
        </Toolbar>
      </AppBar>
      {/* {console.log({ filteredData })} */}
      <div style={{ marginTop: "10px" }}></div>
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellRow}>User Name</TableCell>
              <TableCell className={classes.cellRow}>Email</TableCell>
              <TableCell className={classes.cellRow}>Mobile</TableCell>
              {/* <TableCell className={classes.cellRow}>Gender</TableCell> */}
              <TableCell className={classes.cellRow}>Pin Code</TableCell>
              {/* <TableCell className={classes.cellRow}>Status</TableCell> */}
              {/* {headerValue == "" ? null : <TableCell className={classes.cellRow}>{headerValue}</TableCell>} */}
              <TableCell className={classes.cellRow} style={{textAlign: 'center'}}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length > 0 ? (
              filteredData.map((item) => (
                <TableRow key={item._id}>
                  <TableCell
                    className={classes.cell}
                  >{`${item.firstName} ${item.lastName}`}</TableCell>
                  <TableCell className={classes.cell}>{item.email}</TableCell>
                  <TableCell className={classes.cell}>{item.mobile}</TableCell>
                  {/* <TableCell className={classes.cell}>{item.gender}</TableCell> */}
                  <TableCell className={classes.cell}>{item.pincode}</TableCell>
                  {/* <TableCell className={classes.cell}>
                    {gridUsersStatus(item)}{" "}
                  </TableCell> */}
                  <TableCell className={classes.cell}>
                    {gridItemAction(item)}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {users.length > 0 ? (
          <TablePagination
            count={users.length}
            component="div"
            page={currentPage}
            rowsPerPage={pageSize}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10]}
            ActionsComponent={() => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  onClick={changeToFirstPage}
                  disabled={currentPage === 0}
                >
                  <FirstPageOutlined />
                </Button>
                <Button onClick={changeToPrevPage} disabled={currentPage === 0}>
                  <NavigateBefore />
                </Button>
                <span className="myPage">{currentPage + 1}</span>
                <Button
                  onClick={changeToNextPage}
                  disabled={
                    currentPage >=
                    Math.ceil(users?.length / pageSize) - 1
                  }
                >
                  <NavigateNext />
                </Button>
                <Button
                  onClick={changeToLastPage}
                  disabled={
                    currentPage >=
                    Math.ceil(users?.length / pageSize) - 1
                  }
                >
                  <LastPageOutlined />
                </Button>
              </div>
            )}
          />
        ) : null}
      </TableContainer>
    </div>
  );
};
export default Users;

import React, { useState, useContext, useEffect } from "react";
import StateContext from "../../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import blank from "../../assets/blank.jpg";
import { ImCross } from "react-icons/im";
import { BsFillPencilFill } from "react-icons/bs";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";

const EditProfile = () => {
  const { profileData } = useContext(StateContext);
  //const { token } = useContext(StateContext);

  console.log(profileData);
 
  const [data, setData] = useState(profileData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const navigate = useNavigate();

  console.log(data);

  function mainPage() {
    navigate("/");
  }

  //Image
  const [selectedImages, setSelectedImages] = useState([]);

  const onSelectFile = (event) => {
    // const selectedFiles = event.target.files;
    const selectedFiles = event.target.files[0];
    // const selectedFilesArray = Array.from(selectedFiles);

    // const imagesArray = selectedFilesArray.map((file) => {
    //   return file;
    // });

    setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
    // setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  //ImagePreview
  const [image, setImage] = React.useState("");
  // const [imgUpload, setImgUpload] = useState(false);
  const [serverImage, setServerImage] = useState([""]);

  console.log("image = ", image);

  function deleteImage(e) {
    const s = image.filter((item, index) => index != e);
    setImage(s);
  }

  console.log(image);

  const ImageThumb = ({ image }) => {
    return (
      <div>
        <img src={URL.createObjectURL(image)} alt={image.name} />
      </div>
    );
  };

  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);

  async function postData(e) {
   
    const id = data._id;
    const finalToken = sessionStorage.getItem("token");
    const uploadImage = "https://api.orbitmart.co.in/api/v1/upload";
    const uploadData = "https://api.orbitmart.co.in/api/v1/auth/admin/edit-profile";
    const formData = new FormData();
    formData.append("Image", selectedImages[0]);
    axios
      .post(uploadImage, formData)
      .then((res) => {
       
        console.log(res);
        const newimg = res.data.files;
        const coverPhoto = newimg[0];
        const finalData = {
          firstName: data.firstName,
          lastName: data.lastName,
          firmName: data.firmName,
          address: data.address,
          city: data.city,
          country: data.country,
          email: data.email,
          gender: data.gender,
          mobile: data.mobile,
          pincode: data.pincode,
          state: data.state,
          addharCard: data.addharCard,
          panCard: data.panCard,
          classification: data.classification,
          accountNo: data.accountNo,
          bankAccountType: data.bankAccountType,
          bankName: data.bankName,
          branchName: data.branchName,
          ifscCode: data.ifscCode,
          avatar: coverPhoto,
        };
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-token": finalToken,
          },
          body: JSON.stringify(finalData),
        };
        fetch(uploadData, requestOptions)
          .then(async (response) => {
            const isJson = response.headers
              .get("content-type")
              ?.includes("application/json");
            const data = isJson && (await response.json());
            console.log(data);
            setAlert(true);
            setTimeout(() => {
              mainPage();
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
            setError(true);
            setTimeout(() => {
              mainPage();
            }, 1000);
          });
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setTimeout(() => {
          mainPage();
        }, 1000);
      });
  }

  //   async function postData(e) {
  //    
  //     const merId = id;
  //     const finalDatas = {
  //       firstName: data.firstName,
  //       lastName: data.lastName,
  //       firmName: data.firmName,
  //       address: data.address,
  //       city: data.city,
  //       country: data.country,
  //       email: data.email,
  //       gender: data.gender,
  //       mobile: data.mobile,
  //       pincode: data.pincode,
  //       state: data.state,
  //       addharCard: data.addharCard,
  //       panCard: data.panCard,
  //       classification: data.classification,
  //       accountNo: data.accountNo,
  //       bankAccountType: data.bankAccountType,
  //       bankName: data.bankName,
  //       branchName: data.branchName,
  //       ifscCode: data.ifscCode,
  //       avatar: image,
  //     };
  //     console.log(finalDatas);
  //     e.preventDefault();
  //    
  //     try {
  //       const res = await fetch(
  //         `https://api.orbitmart.co.in/api/v1/product/${merId}`,
  //         {
  //           method: "put",
  //           headers: {
  //             "Content-Type": "application/json",
  //             "x-access-token": "token-value",
  //           },
  //           body: JSON.stringify(finalDatas),
  //         }
  //       );
  //       if (!res.ok) {
  //         const message = `An error has occured: ${res.status} - ${res.statusText}`;
  //         throw new Error(message);
  //       }
  //       const data = await res.json();
  //       const result = {
  //         status: res.status + "-" + res.statusText,
  //         headers: { "Content-Type": res.headers.get("Content-Type") },
  //         data: data,
  //       };
  //       console.log(result);
  //     } catch (err) {
  //       console.log(err.message);
  //     }
  //     mainPage();
  //   }

  console.log(data, "data is json")

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {alert ? (
        <Stack sx={{ width: "100%" }} spacing={2}>
          {/* <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            This is an error alert — <strong>check it out!</strong>
          </Alert>
          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            This is a warning alert — <strong>check it out!</strong>
          </Alert> */}
          <Alert severity="info">
            {/* <AlertTitle>Info</AlertTitle> */}
            Profile Edited <strong>Successfully</strong>
          </Alert>
          {/* <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            This is a success alert — <strong>check it out!</strong>
          </Alert> */}
        </Stack>
      ) : null}
      {error ? (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error">
            Error In Editing— <strong>Profile</strong>
          </Alert>
        </Stack>
      ) : null}
      <h4 class="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
        Edit Profile
      </h4>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          style={{ width: 80 }}
          onClick={() => mainPage()}
          class="backButton"
        >
          Back
        </button>
      </div>
      <form>
        <div class="grid xl:grid-cols-3  m:gap-2 form-group">
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>First Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="firstName"
              value={data.firstName}
              onChange={handleChange}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Last Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="lastName"
              value={data.lastName}
              onChange={handleChange}
            />
          </div>

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Firm Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="firmName"
              value={data.firmName}
              onChange={handleChange}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Address</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="address"
              value={data.address}
              onChange={handleChange}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>City</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="city"
              value={data.city}
              onChange={handleChange}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Country</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="country"
              value={data.country}
              onChange={handleChange}
            />
          </div> */}

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Email</label>
            <input
              type="email"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="email"
              value={data.email}
              onChange={handleChange}
              disabled
            />
          </div>

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Gender</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="gender"
              value={data.gender}
              onChange={handleChange}
            >
              <option selected>Select Value</option>
              <option value="MALE">Male</option>
              <option value="FEMALE">Female</option>
            </select>
          </div> */}

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Mobile</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="mobile"
              value={data.mobile}
              onChange={handleChange}
            />
          </div>
{/* 
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>PinCode</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="pincode"
              value={data.pincode}
              onChange={handleChange}
            />
          </div> */}

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label>State</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="state"
              value={data.state}
              onChange={handleChange}
            />
          </div> */}

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Adhar Card</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="addharCard"
              value={data.addharCard}
              onChange={handleChange}
            />
          </div> */}

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Pan Card</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="panCard"
              value={data.panCard}
              onChange={handleChange}
            />
          </div> */}

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Account No</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="accountNo"
              value={data.accountNo}
              onChange={handleChange}
            />
          </div> */}

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Bank Account Type</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="bankAccountType"
              value={data.bankAccountType}
              onChange={handleChange}
            >
              <option selected>Select Value</option>
              <option value="SAVING">Saving</option>
              <option value="CURRENT">Current</option>
            </select>
          </div> */}

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Bank Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="bankName"
              value={data.bankName}
              onChange={handleChange}
            />
          </div> */}

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Branch Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="branchName"
              value={data.branchName}
              onChange={handleChange}
            />
          </div> */}

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label>IFSC Code</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="ifscCode"
              value={data.ifscCode}
              onChange={handleChange}
            />
          </div> */}

          <div style={{ marginLeft: 20 }}>
            <label>Avatar Image</label>
            <div class="relative mt-1 flex justify-center w-40">
              <div class=" absolute right-0 top-0 z-10 flex">
                <label
                  for="file-upload"
                  class="cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none"
                >
                  <BsFillPencilFill />
                  <input
                    id="file-upload"
                    name="file-upload"
                    disabled={selectedImages.length == 1}
                    accept="image/*"
                    type="file"
                    onChange={onSelectFile}
                    class="sr-only"
                  />
                </label>
              </div>
              <div class="relative z-0 p-2 w-full mb-6 group">
                {selectedImages.length > 0 ? (
                  selectedImages.map((item, index) => {
                    return (
                      <div style={{ display: "inline-block" }} key={item}>
                        <p className="block-icon">
                          <img src={URL.createObjectURL(item)} alt="img" />
                          <ImCross
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandler(item)}
                            className="icon-belowtag"
                          />
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <img src={data.avatar} alt="user-profile" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          style={{ width: 80 }}
          onClick={postData}
          class="addButton"
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default EditProfile;
